import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import { FieldArray } from 'react-final-form-arrays';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { DateField, Select } from '../../../components/Form';
import { AdPeriod, IAd, IAdSchedule } from '../../../api/ads';
import { required } from '../../../validators';
import { Divider, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { AdCost } from './AdCost';
import { format } from 'date-fns';


const useStyles = makeStyles((theme) => ({
    root: {},
    row: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        justifyContent: 'space-between',
        '& > *': {
            width: '48%',
        },
    },
    deleteSchedule: {
        padding: 4,
    },
    divider: {
        marginBottom: theme.spacing(2),
        backgroundColor: 'rgb(0 0 0 / 40%)',
    },
}));

const adPeriodLabels = {
    [AdPeriod.DAYTIME]: 'Только день',
    [AdPeriod.NIGHT]: 'Только ночь',
    [AdPeriod.ALL_DAY]: 'Сутки',
}

const periodSelectOptions = Object.values(AdPeriod).map((f) => ({
    value: f,
    label: adPeriodLabels[f],
}))

interface IProps {
    item: IAd;
    save: any;
    saving: boolean;
}

function prepareSchedule(schedule: IAdSchedule[]): IAdSchedule[] {
    return schedule.map((s) => {
        const dateFrom: Date = typeof s.dateFrom === 'string' ? new Date(s.dateFrom) : s.dateFrom;
        const dateTo: Date = typeof s.dateTo === 'string' ? new Date(s.dateTo) : s.dateTo;

        return {
            ...s,
            // clear timezone
            dateFrom: new Date(format(startOfDay(dateFrom), 'yyyy-MM-dd')),
            dateTo: endOfDay(dateTo),
        };
    });
}

export default function AdSchedule({ item, save, saving }: IProps) {
    const classes = useStyles();

    const initial = {
        schedule: item.schedule,
    };

    return (
        <>
            <Form onSubmit={(values) => save({ ...item, schedule: prepareSchedule(values.schedule) })}
                  initialValues={initial}
                  mutators={{ ...arrayMutators }}
                  render={({ handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                          <FieldArray name="schedule">
                              {({ fields }) => (
                                  <>
                                      {fields.map((n, i) => (
                                          <div key={i}>
                                              <Grid container spacing={2}>
                                                  <Grid item md={11}>
                                                      <div className={classes.row}>
                                                          <DateField name={`${n}.dateFrom`}
                                                                     label="Старт ротации"
                                                                     validate={required}/>
                                                          <DateField name={`${n}.dateTo`}
                                                                     label="Окончание ротации"
                                                                     validate={required}/>
                                                      </div>
                                                      <div className={classes.row}>
                                                        <Select name={`${n}.period`}
                                                            label="Период показа"
                                                            options={periodSelectOptions}
                                                            validate={required}/>
                                                      </div>
                                                  </Grid>
                                                  <Grid item md={1}>
                                                      <IconButton aria-label="delete"
                                                                  color="secondary"
                                                                  onClick={() => { fields.remove(i); }}
                                                                  className={classes.deleteSchedule}>
                                                          <ClearIcon fontSize="small"/>
                                                      </IconButton>
                                                  </Grid>
                                              </Grid>
                                              <Divider light={false} className={classes.divider}/>
                                          </div>
                                      ))}
                                      {!fields.length && <Button variant="text"
                                                                 size="small"
                                                                 color="primary"
                                                                 onClick={() => fields.push({
                                                                     dateFrom: null,
                                                                     dateTo: null,
                                                                     period: AdPeriod.DAYTIME
                                                                 })}>
                                          Добавить
                                      </Button>}
                                  </>
                              )}
                          </FieldArray>
                          <Box my={2}>
                              <Button type="submit" variant="contained" color="primary" size="large" disabled={saving}>
                                  Сохранить
                              </Button>
                          </Box>
                      </form>
                  )}/>

            <AdCost ad={item} schedule={item.schedule?.[0]}/>
        </>
    );
}
